import React from "react"
import Logo from "assets/images/fitfarm-logo-for-white-background.svg"

const ContactForm = () => {
  return (
    <section className="container grid-white u-clear-banner u-mt-5">
      <div className="green-skew"></div>
      <h1 className=" type-heavy type-black type-header__contact">
        <span className="type-emph type-white type-break__after  ">
          Contact
        </span>{" "}
        FitFarm
      </h1>

      <div className="grid-two-col">
        <div className="u-p-2 grid-align-center">
          <h3 className="type-black type-heavy type-header__fourth">
            We're Here to Help
          </h3>
          <p className="type-black type-body type-reg u-mt-1">
            Are you interested in CrossFit, Personal Training, Nutrition, or do
            you want to talk about something else? You can visit us at one of
            our locations, give us a call, or fill out the form. We will reach
            out to you right away.
          </p>
          <ul className="u-mt-1">
            <li className="type-reg type-black type-body li li-green">
              <span className="type-heavy">Phone: </span>
              <a href="tel:(402)-614-6446">(402) 614-6446</a>
            </li>
            <li className="type-reg type-black type-body li li-green">
              <span className="type-heavy">Email: </span>
              <a href="mailto:info@fitfarmomaha.com">info@fitfarmomaha.com</a>
            </li>
          </ul>
          <h3 className="type-black type-heavy type-header__fourth">
            Schedule a Meeting
          </h3>
          <ul className="u-mt-2">
            <li className="type-heavy type-green type-body li li-green">
              <a
                href="https://calendly.com/fitfarmomaha"
                target="_blank"
                rel="noreferrer"
              >
                CrossFit or Personal Training
              </a>
            </li>
            <li className="type-heavy type-green type-body li li-green">
              <a
                href="https://calendly.com/fitfarmnutrition/"
                target="_blank"
                rel="noreferrer"
              >
                Nutrition and Weight Loss
              </a>
            </li>
          </ul>
        </div>
        <div
          className="form-contact__container
        "
        >
          <div className="form-card">
            <form
              name="contact"
              method="POST"
              data-netlify="true"
              netlify-honeypot="bot-field"
              action="/success"
            >
              <label htmlFor="bot-field" style={{ display: "none" }} />
              <input type="hidden" name="bot-field" />
              <label htmlFor="form-name" style={{ display: "none" }} />
              <input type="hidden" name="form-name" value="contact" />
              <div className="form-spacing__tb">
                <label
                  htmlFor="name"
                  className="form-label type-heavy type-form__label"
                >
                  Name:
                </label>
                <input type="text" name="name" className="form-input__text" />
              </div>

              <div className="form-grid">
                <div className="">
                  <label
                    htmlFor="email"
                    className="form-label type-heavy type-form__label"
                  >
                    Email:
                  </label>
                  <input
                    type="text"
                    name="email"
                    className="form-input__text"
                  />
                </div>
                <div className="">
                  <label
                    htmlFor="phone"
                    className="form-label type-heavy type-form__label"
                  >
                    Phone:
                  </label>
                  <input
                    type="text"
                    name="phone"
                    className="form-input__text"
                  />
                </div>
              </div>

              <p className="type-heavy type-form__label form-spacing__tb">
                What Are you Interested In?
              </p>
              <div className="form-grid">
                <div className="">
                  <input
                    type="checkbox"
                    name="crossfit"
                    className="form-input__checkbox"
                  />
                  <label htmlFor="crossfit" className="type-form__checkbox">
                    CrossFit
                  </label>
                </div>
                <div className="">
                  <input
                    type="checkbox"
                    name="nutrition"
                    className="form-input__checkbox"
                  />
                  <label htmlFor="nutrition" className="type-form__checkbox">
                    Nutrition
                  </label>
                </div>
                <div className="">
                  <input
                    type="checkbox"
                    name="personaltraining"
                    className="form-input__checkbox"
                  />
                  <label
                    htmlFor="personaltraining"
                    className="type-form__checkbox"
                  >
                    Personal Training
                  </label>
                </div>
                <div className="">
                  <input
                    type="checkbox"
                    name="somethingelse"
                    className="form-input__checkbox"
                  />
                  <label
                    htmlFor="somethingelse"
                    className="type-form__checkbox"
                  >
                    Something Else
                  </label>
                </div>
              </div>
              <p className="type-heavy type-form__label form-spacing__tb">
                How can we help?
              </p>
              <textarea
                rows="5"
                name="message"
                className="form-input__text form-input__textarea"
              />
              <div className="u-center-text u-mt-1">
                <button
                  type="submit"
                  className="btn btn-primary btn-primary__gold type-heavy type-black u-center-text"
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <img
        src={Logo}
        className="img-background img-background__white"
        alt="FitFarm Logo reversed nearly transparent"
      />
    </section>
  )
}

export default ContactForm
